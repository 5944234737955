import React from "react"
import { Link } from "gatsby"
import {
  Row,
  Col,
  Container,
  Breadcrumb,
} from "react-bootstrap"

import Layout from "../../components/layout"
import Seo from "../../components/seo"


import LuteinComplexMobile from "./../../images/about-mobile.jpg"
import LuteinComplexTablet from "./../../images/about-tablet.jpg"
import LuteinComplexDesktop from "./../../images/about-desktop.jpg"
import LuteinComplexXL from "./../../images/about-desktop-xl.jpg"

const ContactPageEn = () => (
  <Layout backButton={true} enSite pageInfo={{ pageName: "contact-en" }}>
    <Seo title="Contact us" />
    <Container className="p-0">
      <Row>
        <Col className="d-none d-md-block">
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to="/en">HOME</Link>
            </li>
            <Breadcrumb.Item active>CONTACTS</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <picture>
            <source srcSet={LuteinComplexXL} media="(min-width: 1200px)" />
            <source srcSet={LuteinComplexDesktop} media="(min-width: 992px)" />
            <source srcSet={LuteinComplexTablet} media="(min-width: 767px)" />
            <source srcSet={LuteinComplexMobile} media="(min-width: 370px)" />
            <img
              srcSet={LuteinComplexMobile}
              alt="Contact Vita Herb"
              className="w-100 fluid rounded mb-4"
            />
          </picture>
        </Col>
      </Row>

      <Row className="mb-5 product-container">
        <Col sm={12} className="">
          <h1 className="display-5 font-light mb-4 large-heading-mobile">
            Contacts
          </h1>
        </Col>
        <Col sm={12} className="order-md-0">
          <p>
            Sofia 1113, Bulgaria <br />
            18 Shipchenski Prohod Blvd.<br />
            Galaxy Trade Center, level -1, shop 007<br />
            Registered in Bulgarian Trade Registry with EIC: 175150004
          </p>

          <ul className="list-unstyled">
            <li>Tel: +359 2 944 6006</li>
            <li>Tel: +359 876 18 1000</li>
            <li>e-mail: info@vitaherb.bg</li>
          </ul>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default ContactPageEn
